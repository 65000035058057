import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = 'api/v1/notification_category'

export default {
    postAddBlacklistNotificationForUserConnected(idNotificationCategory: number): Promise<AxiosResponse<void>> {
        return axios.post(`${resource}/${idNotificationCategory}/blacklist`)
    },
}
