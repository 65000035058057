<template>
    <div id="notification-page">
        <toolbar-back-button-component :title="$t('notification_page.notifications')" />

        <div class="pa-4">
            <p class="font-color-medium font-14">
                {{ $t('notification_page.total_notifications', [$store.getters['notifications/total_notifications_not_see']]) }}
            </p>

            <v-card
                v-for="(notification, index) in notifications"
                :key="notification.id_notification"
                :class="notification.is_read ? 'mb-2' : 'mb-2 unread-notification-card'"
                elevation="0"
            >
                <v-card-title class="pb-2 pt-2">
                    <v-icon :color="notification.notification_category.icon_color" class="mr-2" small>
                        {{ notification.notification_category.icon }}
                    </v-icon>
                    <span class="font-weight-bold font-16">{{ notification.label }}</span>

                    <v-spacer />

                    <menu-actions-component
                        :actions="[
                            {
                                title: $t('notification_page.disable_notification'),
                                icon: 'mdi-bell-off-outline',
                                emit: 'prepareUnsubscribeNotificationCategory',
                                has_access: true,
                            },
                            {
                                title: $t('notification_page.delete_notification'),
                                icon: 'mdi-delete-outline',
                                emit: 'prepareDeleteNotification',
                                has_access: true,
                                color: 'error'
                            },
                        ]"
                        v-on:prepareUnsubscribeNotificationCategory="prepareUnsubscribeNotificationCategory(notification)"
                        v-on:prepareDeleteNotification="prepareDeleteNotification(notification, index)"
                    />
                </v-card-title>

                <v-card-text class="pb-2">
                    <v-row class="ma-0">
                        <v-col class="pa-0" cols="8">
                            <span class="font-color-light font-12">{{ notification.description }}</span>
                        </v-col>

                        <v-col class="pa-0 pl-10">
                            <v-row class="ma-0">
                                <v-spacer />
                                <span class="font-color-light">{{ moment(notification.date_creation).fromNow() }}</span>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions v-if="notification.button_url" class="px-4 pb-4">
                    <v-btn color="secondary" outlined small @click="updateIsReadNotification(notification)">
                        <span class="secondary--text font-weight-bold">
                            {{ $t(`notification_page.${notification.button_text}`) }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>

        <v-dialog v-model="dialog_delete_notification" max-width="500px">
            <v-card>
                <v-card-title class="mb-0">
                    <p class="font-weight-bold font-18 text-uppercase error--text">
                        {{ $t('notification_page.delete_notification') }}
                    </p>
                </v-card-title>

                <v-card-text class="py-0">
                    <span>
                        {{ $t('notification_page.confirm_delete_notification', [notification_selected.label]) }}
                    </span>
                </v-card-text>

                <v-card-actions class="justify-end pb-4">
                    <v-btn color="accent" text @click="closeDialog()">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn class="btn-secondary-loader font-weight-bold" text @click="deleteNotification()">
                        <span class="error--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_unsubscribe_notification" max-width="500px">
            <v-card>
                <v-card-title class="mb-0">
                    <p class="font-weight-bold font-18 text-uppercase">
                        {{ $t('notification_page.disable_notification') }}
                    </p>
                </v-card-title>

                <v-card-text class="py-0">
                    <span>
                        {{ $t('notification_page.confirm_disable_notification', [notification_selected.label]) }}
                    </span>
                </v-card-text>

                <v-card-actions class="justify-end pb-4">
                    <v-btn color="accent" text @click="closeDialog()">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn class="btn-secondary-loader font-weight-bold" text @click="unsubscribeNotificationCategory()">
                        <span class="secondary--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="bottom-hidden-div" />
    </div>
</template>

<script>
import ToolbarBackButtonComponent from '../components/Global/ToolbarBackButtonComponent'
import NotificationRepository from '@/repositories/NotificationRepository'
import NotificationCategoryRepository from '@/repositories/NotificationCategoryRepository'
import MenuActionsComponent from '@/components/Global/MenuActionsComponent.vue'

export default {
    name: 'NotificationPage',
    components: { MenuActionsComponent, ToolbarBackButtonComponent },
    data() {
        return {
            sheet_edit_notification: false,
            notification_selected: {},
            index_notification_to_delete: null,
            dialog_delete_notification: false,
            id_notification_category: null,
            dialog_unsubscribe_notification: false,
        }
    },
    computed: {
        notifications: {
            get: function () {
                return this.$store.getters['notifications/notifications']
            },
            set: function (value) {
                this.$store.dispatch('notifications/getNotifications', this.$store.getters['user_connected/user_connected'].id_client)
            },
        },
    },
    created() {
        let idNotifications = []
        this.$store.getters['notifications/notifications'].forEach((notification) => {
            if (!notification.is_see) {
                idNotifications.push(notification.id_notification)
            }
        })

        if (idNotifications.length > 0) {
            NotificationRepository.updateIsSeeNotifications(idNotifications)
                .then(() => {
                    this.$store.dispatch('notifications/setNotificationsSee')
                })
                .catch((error) => {
                    this.manageError(error)
                })
        }
    },
    methods: {
        prepareUnsubscribeNotificationCategory(notification) {
            this.id_notification_category = notification.notification_category.id_notification_category
            this.notification_selected = notification
            this.dialog_unsubscribe_notification = true
        },

        unsubscribeNotificationCategory() {
            NotificationCategoryRepository.postAddBlacklistNotificationForUserConnected(this.id_notification_category)
                .then(() => {
                    this.$store.dispatch(
                        'notifications/setNotificationsReadAndSeeWithIdNotificationCategory',
                        this.id_notification_category
                    )
                    this.showSnackbar('success', this.$t('notification_page.category_disable'))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.closeDialog()
                })
        },

        prepareDeleteNotification(notification, indexNotification) {
            this.notification_selected = notification
            this.index_notification_to_delete = indexNotification
            this.dialog_delete_notification = true
        },

        deleteNotification() {
            NotificationRepository.deleteNotificationById(this.notification_selected.id_notification)
                .then(() => {
                    this.$store.dispatch('notifications/deleteNotificationById', this.index_notification_to_delete)
                    this.showSnackbar('success', this.$t('notification_page.notification_delete'))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.closeDialog()
                })
        },

        updateIsReadNotification(notification) {
            NotificationRepository.updateIsReadNotifications([notification.id_notification])
                .then(() => {
                    this.$router.push(`${notification.button_url}`).then(() => {
                        this.$store.dispatch('notifications/setNotificationRead', notification.id_notification)
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        closeDialog() {
            this.dialog_delete_notification = false
            this.dialog_unsubscribe_notification = false
            this.notification_selected = {}
        },

        swipeBottomHandler() {
            this.sheet_edit_notification = false
        },
    },
}
</script>